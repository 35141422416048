<!--
 * @Description: 用户服务协议
 * @Author: chengXiaoQi
 * @Date: 2022-11-17 10:44:55
 * @LastEditors: chengXiaoQi
 * @LastEditTime: 2022-12-19 18:12:00
 * @FilePath: /web-app/src/pages/Pact/ServiceAgreement.vue
-->
<template>
  <el-result title="用户服务协议" sub-title="用户服务协议用户服务协议用户服务协议用户服务协议">
    <template #icon> </template>
    <template #extra> </template>
  </el-result>
</template>
<script lang="ts" setup></script>
